import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { renderToString } from 'react-dom/server';
import clone from 'lodash/clone';
import AccordionGroup from '@bfbs/storybook/dist/components/organisms/accordion-group/accordion-group';
import Icon from '@bfbs/storybook/dist/components/atoms/icon/icon';

const HowToListenHome = () => {
  const data = useStaticQuery(graphql`
    query HowToListenQuery {
      allHowToListenJson {
        nodes {
          title
          content
          icon
          links {
            path
            icon
            alt
          }
        }
      }
    }
  `);

  /**
   * Render the links using components.
   *
   * @param links
   * @returns {*}
   */
  const renderLinks = links => {
    const renderedLinks = links.map(link => {
      return renderToString(
        <a href={link.path} title={link.alt}>
          <Icon icon={link.icon} size="xl" />
        </a>
      );
    });

    return renderedLinks.join('&nbsp;');
  };

  /**
   * Update the data to use the keys that Accordion group is expecting.
   *
   * @param nodes
   * @returns {*}
   */
  const prepareAccordionItems = nodes => {
    return nodes.map(node => {
      // Clone to avoid duplicating the links.
      const item = clone(node);
      if (item.links) {
        item.content = item.content + renderLinks(item.links);
      }
      item.isOpen = false;

      return item;
    });
  };

  return (
    <AccordionGroup heading="How to listen" accordionData={prepareAccordionItems(data.allHowToListenJson.nodes)} />
  );
};

export default HowToListenHome;
