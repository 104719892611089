import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Path from '../../services/path';
import { preparePodcastCards, preparePodcastListing } from '../../services/podcast-helper';
import CardGroup from '@bfbs/storybook/dist/components/organisms/card-group/card-group';

const PodcastTeaser = ({ count }) => {
  const { allFeedBfbsPodcastsItems } = useStaticQuery(graphql`
    query AllPodcasts {
      allFeedBfbsPodcastsItems(sort: { fields: isoDate, order: DESC }) {
        nodes {
          title
          content
          series
          new_episode
          itunes {
            image
            summary
          }
        }
      }
    }
  `);

  const podcastItems = preparePodcastListing(allFeedBfbsPodcastsItems.nodes);
  const podcastCards = preparePodcastCards(podcastItems);

  return (
    <CardGroup
      customClasses="o-card-group--podcasts o-card-group--grid-4"
      isSlider={false}
      cards={podcastCards.slice(0, count)}
      heading="Podcasts & Specials"
      ctaUrl={Path.podcastList}
      ctaText="See all Podcasts & Specials"
      background="background--secondary-8"
    />
  );
};

PodcastTeaser.propTypes = {
  count: PropTypes.number.isRequired,
};

export default PodcastTeaser;
