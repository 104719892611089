import React from 'react';
import Path from '../../services/path';
import { graphql, useStaticQuery } from 'gatsby';
import { prepareAllPresenterData } from '../../services/presenter-helper';
import CardGroup from '@bfbs/storybook/dist/components/organisms/card-group/card-group';

// @todo: Sort order need to be updated.
const PresenterTeaserBlock = () => {
  const data = useStaticQuery(
    graphql`
      query PresentersAllTopTen {
        allPresenter(limit: 6, filter: { active: { eq: true } }, sort: { fields: title, order: ASC }) {
          edges {
            node {
              title
              active
              field_values {
                image
              }
            }
          }
        }
      }
    `
  );

  return (
    <CardGroup
      background="background--secondary-8"
      heading="Presenters"
      isSlider={false}
      cards={prepareAllPresenterData(data)}
      ctaUrl={Path.presenterList}
      ctaText="See all presenters"
      customClasses="row--padding-y o-card-group--rounded"
    />
  );
};

export default PresenterTeaserBlock;
