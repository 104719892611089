import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Path from '../../services/path';
import CardGroup from '@bfbs/storybook/dist/components/organisms/card-group/card-group';
import { prepareAllStationData } from '../../services/station-helper';

const StationTeaserBlock = () => {
  /**
   * Fetch the first 10 stations.
   *
   * @type {any}
   */
  const data = useStaticQuery(
    graphql`
      query StationsAllTopTen {
        allStation(filter: {metadatastation: {weight: {ne: 0}}}, limit: 6, sort: { fields: station___station___metadatastation___weight, order: ASC }) {
          edges {
            node {
              title
              metadatastation {
                logo128x128
                weight
                svg1by1
              }
            }
          }
        }
      }
    `
  );

  /**
   * Return a CardGroup component with the station cards.
   */
  return (
    <CardGroup
      isSlider={false}
      cards={prepareAllStationData(data)}
      ctaUrl={Path.stationList}
      ctaText="See all stations"
      customClasses="row--padding-y o-card-group--rounded-img-padding o-card-group--rounded"
    />
  );
};

export default StationTeaserBlock;
