import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import PresenterTeaserBlock from '../templates/presenter/presenter-teaser-block';
import StationTeaserBlock from '../templates/station/station-teaser-block';
import HowToListenHome from '../components/how-to-listen-home';
import CatchupBlock from '../components/catchup/catchup';
import IconTitle from '@bfbs/storybook/dist/components/molecules/icon-title/icon-title';
import Container from '../components/container';
import PodcastTeaser from '../components/podcast/podcast-teaser';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Container>
      <IconTitle
        heading="Radio"
        icon="radio"
        text="Bringing you news and live music from the UK and around the world"
      />
    </Container>

    <StationTeaserBlock />

    <PodcastTeaser count={4} />

    <CatchupBlock count={4} />

    <PresenterTeaserBlock />

    <HowToListenHome />
  </Layout>
);

export default IndexPage;
