const Path = require('./path');
const trim = require('./trim');

module.exports = {
  /**
   * Return an array of objects for the card group component.
   *
   * @param podcastItems
   * @returns {*}
   */
  preparePodcastCards: podcastItems => {
    return podcastItems.map(row => {
      const name = row.series.length > 0 ? row.series[0] : row.title;
      const href = Path.getPodcastPath(name);

      return {
        image: row.itunes.image,
        title: row.title,
        podcast: true,
        // text: trim(row.itunes.summary),
        rounded: false,
        href: href,
        newEpisode: row.new_episode,
        // episodesLink: {
          // href: href,
          // text: row.series.length ? 'Episodes' : 'Listen now',
          // title: row.series.length ? 'Episodes' : 'Listen now',
        // },
      };
    });
  },

  /**
   * Filter episodes based on categories.
   *
   * @param podcastItems
   * @returns {[]}
   */
  preparePodcastListing: podcastItems => {
    let seriesUsed = [];
    const list = [];
    // Loop through each item.
    podcastItems.forEach(episode => {
      // Not excluded by default.
      let exclude = false;

      // Check if an episode with the same series has already been included.
      episode.series.forEach(category => {
        if (seriesUsed.includes(category)) {
          exclude = true;
        }
      });

      // If part of a unique series, include the episode and update the used series.
      if (exclude === false) {
        seriesUsed = [...seriesUsed, ...episode.series];
        list.push(episode);
      }
    });

    return list;
  },

  /**
   * Get the series defined in the podcast items.
   *
   * This will only return distinct items.
   *
   * @param podcastItems
   * @returns {*[]}
   */
  getSeriesFromPodcastItems: podcastItems => {
    const allSeries = podcastItems.reduce((series, item) => series.concat(item.series), []);

    return Array.from(new Set(allSeries));
  },

  /**
   * Filter out any episodes that are part of a series.
   *
   * @param podcastItems
   * @returns {[]}
   */
  getOneOffEpisodes: podcastItems => podcastItems.filter(item => !item.series.length),
};
